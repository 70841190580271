import React, { Suspense, useEffect } from 'react';
import { Outlet, useParams, useNavigate } from 'react-router-dom';
import styles from './index.less';

const logo = require('@/assets/images/common/logo.png');

const Home = () => {
  useEffect(() => {

  }, []);
  return (
    <div className={styles.home}>
      <Suspense fallback="loading...">
        <Outlet />
      </Suspense>
      <img className={styles.logo} src={logo} />
    </div>
  );
};

export default Home;
